<template>
  <div class="mb-32">
      <section class="pt-8 xl:h-screen  bg-cover bg-no-repeat min-h-2/4 object-cover bg-opacity-75 background_image relative">
         <div class=" text-white absolute bottom-0 mb-6 w-full">
          <div class="container mx-auto px-6 xl:px-12 text-center">
            <h1 class="text-5xl sm:text-6xl lg:text-8xl xl:text-9xl font-bold">
             {{ $t("Culture.title") }}   
            </h1>
            
          </div>
        </div>
      </section>

      <section class="container mx-auto px-6 xl:px-12 text-center mt-32">
        <p class="text-2xl">
                       {{ $t("Culture.section1") }}   

          
        </p>

        <p class="text-2xl mt-2">
                       {{ $t("Culture.section2") }}   

          
        </p>
      </section>

      <section class="container max-w-5xl mx-auto px-6 xl:px-12 text-center mt-32 grid md:grid-cols-2">

          <div class="w-full relative">
            <button @click="art = true" class="w-full p-0 h-66 border border-greey flex flex-col justify-center items-center text-5xl lg:text-6xl font-bold hover:shadow-lg focus:outline-none">
                Art
            </button>
            <div v-show="art" class="absolute bg-white inset-0 border border-greey shadow-lg">
                <div class="p-4 h-full">
                  <button @click="art = false" class=" hover:scale-y-150 duration-500 transform focus:outline-none">
                    <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="34.526" height="13.15" viewBox="0 0 34.526 13.15">
                      <path id="Path_39" data-name="Path 39" d="M495.695,32.922,480.5,23.341l-15.194,9.581" transform="translate(-463.237 -21.841)" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </svg>
                  </button>
                  <div class="flex flex-col justify-center items-center h-full">
                    <p class="text-3xl">
                      Mohamed Alkharrubi
                    </p>
                    <p class="text-3xl">
                      Alaa  Abudabbus
                    </p>
                    <p class="text-3xl">
                      Hadia Gana
                    </p>
                  </div>
                </div>
            </div>
          </div>

          <div class="w-full relative">
            <button @click="Animation = true" class="w-full p-0 h-66 border border-greey flex flex-col justify-center items-center text-5xl lg:text-6xl font-bold hover:shadow-lg focus:outline-none">
                Animation
            </button>
            <div v-show="Animation" class="absolute bg-white inset-0 border border-greey shadow-lg">
                <div class="p-4 h-full">
                  <button @click="Animation = false" class=" hover:scale-y-150 duration-500 transform focus:outline-none">
                    <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="34.526" height="13.15" viewBox="0 0 34.526 13.15">
                      <path id="Path_39" data-name="Path 39" d="M495.695,32.922,480.5,23.341l-15.194,9.581" transform="translate(-463.237 -21.841)" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </svg>
                  </button>
                  <div class="flex flex-col justify-center items-center h-full">
                    <p class="text-3xl">
                      Mohamed Alkharrubi
                    </p>
                    <p class="text-3xl">
                      Alaa  Abudabbus
                    </p>
                    <p class="text-3xl">
                      Hadia Gana
                    </p>
                  </div>
                </div>
            </div>
          </div>

          <div class="w-full relative">
            <button @click="Design = true" class="w-full p-0 h-66 border border-greey flex flex-col justify-center items-center text-5xl lg:text-6xl font-bold hover:shadow-lg focus:outline-none">
                Design
            </button>
            <div v-show="Design" class="absolute bg-white inset-0 border border-greey shadow-lg">
                <div class="p-4 h-full">
                  <button @click="Design = false" class=" hover:scale-y-150 duration-500 transform focus:outline-none">
                    <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="34.526" height="13.15" viewBox="0 0 34.526 13.15">
                      <path id="Path_39" data-name="Path 39" d="M495.695,32.922,480.5,23.341l-15.194,9.581" transform="translate(-463.237 -21.841)" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </svg>
                  </button>
                  <div class="flex flex-col justify-center items-center h-full">
                    <p class="text-3xl">
                      Mohamed Alkharrubi
                    </p>
                    <p class="text-3xl">
                      Alaa  Abudabbus
                    </p>
                    <p class="text-3xl">
                      Hadia Gana
                    </p>
                  </div>
                </div>
            </div>
          </div>

          <div class="w-full relative">
            <button @click="Photography = true" class="w-full p-0 h-66 border border-greey flex flex-col justify-center items-center text-5xl lg:text-6xl font-bold hover:shadow-lg focus:outline-none">
                Photography
            </button>
            <div v-show="Photography" class="absolute bg-white inset-0 border border-greey shadow-lg">
                <div class="p-4 h-full">
                  <button @click="Photography = false" class=" hover:scale-y-150 duration-500 transform focus:outline-none">
                    <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="34.526" height="13.15" viewBox="0 0 34.526 13.15">
                      <path id="Path_39" data-name="Path 39" d="M495.695,32.922,480.5,23.341l-15.194,9.581" transform="translate(-463.237 -21.841)" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </svg>
                  </button>
                  <div class="flex flex-col justify-center items-center h-full">
                    <p class="text-3xl">
                      Mohamed Alkharrubi
                    </p>
                    <p class="text-3xl">
                      Alaa  Abudabbus
                    </p>
                    <p class="text-3xl">
                      Hadia Gana
                    </p>
                  </div>
                </div>
            </div>
          </div>

      </section>

      <section class="container max-w-5xl mx-auto px-6 xl:px-12 text-center mt-16">
        <p class="text-4xl md:text-6xl lg:text-7xl font-bold">
                       {{ $t("Culture.section3") }}   

        </p>
        <p class="text-2xl">
                       {{ $t("Culture.section4") }}   
        </p>
      </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      art:false,
      Animation:false,
      Design:false,
      Photography:false,
    };
  },
  
  methods: {
   
  },
};
</script>

<style >
.background_image{
    background-image: url('../assets/images/Asset 21@300x.jpg')
  }
</style>